import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [categories, setCategories] = useState([]); // Initial empty state

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await fetch('/fetch_categories.php'); // Call the PHP file
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json(); // Convert the response to JSON
      setCategories(data); // Update the state with data from the database
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories(); // Call the fetch function on mount
  }, []);

  return (
    <div className="footer__container">
      <footer>
        <ul className="footer__categories">
          {categories.map((category, index) => (
            <li key={index}>
              <Link to={`/posts/categories/${category.name}`}>{category.name}</Link>
            </li>
          ))}
        </ul>
        <div className="footer__copyright">
          <div>All Rights Reserved &copy;</div>
          <div>Copyright Retrogamedia 2024.</div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
