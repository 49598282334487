import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PostAuthor = ({ authorName }) => {
  const [authorDetails, setAuthorDetails] = useState(null);

  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        const response = await fetch(`/fetch_posts.php?type=by_author&author_id=${authorName}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched author details:', data); // Debugging statement
        setAuthorDetails(data);
      } catch (error) {
        console.error('Error fetching author details:', error);
      }
    };

    fetchAuthorDetails();
  }, [authorName]);

  if (!authorDetails) {
    return <p>Loading author details...</p>;
  }

  return (
    <div>
      <Link to={`/posts/users/${authorDetails.author_name}`} className='post__author'>
        <div className="post__author-avatar">
          <img src={authorDetails.avatar} alt={authorDetails.author_name} />
        </div>
        <div className="post__author-details">
          <h5>By: {authorDetails.author_name}</h5>
          <small>{authorDetails.timestamp}</small>
        </div>
      </Link>
    </div>
  );
};

export default PostAuthor;
