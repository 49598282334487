import React, { useState, useEffect } from 'react'
import PostItem from '../components/PostItem'

const AuthorPosts = () => {
  const [posts, setPosts] = useState([])

  // Fetch post di un account specifico
  const fetchPostsByAuthor = async (authorId) => {
    try {
      const response = await fetch(`/fetch_posts.php?type=by_author&author_id=${authorId}`);
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error('Errore durante il fetch dei post di un autore:', error);
    }
  };

  useEffect(() => {
    fetchPostsByAuthor(); // Chiama la funzione fetch all'inizio
  }, []);

  return (
    <section className="posts">
        {posts.length > 0 ? <div className="container posts__container">
            {
                // key = postID = id allows no error due to duplicate on server
                posts.map(({id, thumbnail, category, title, content, authorID}) => 
                <PostItem key={id} postID={id} thumbnail={thumbnail} category={category} title={title} content={content} authorID={authorID} />)
            }
        </div> : <h2 class='center'>Nessun Autore Trovato</h2>}
    </section>
  )
}

export default AuthorPosts
