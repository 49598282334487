import React from 'react'

const DeletePost = () => {
  return (
    <div>
      deletepost under development
    </div>
  )
}

export default DeletePost
