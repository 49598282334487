import React from 'react'
import Posts from '../components/Posts'

const Home = () => {
  return (
    <Posts></Posts>
  )
}

export default Home
