import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PostDetail = () => {
  const { id } = useParams(); // Get the post ID from the URL
  const [post, setPost] = useState(null); // Initial state is null

  // Fetch post details
  const fetchPostDetails = async () => {
    try {
      const response = await fetch(`/fetch_posts.php?type=by_id&id=${id}`); // Call the PHP file with the post ID
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json(); // Convert the response to JSON
      setPost(data); // Update the state with data from the database
    } catch (error) {
      console.error('Error fetching post details:', error);
    }
  };

  useEffect(() => {
    fetchPostDetails(); // Call the fetch function on mount
  }, [id]);

  if (!post) {
    return <p>Loading...</p>; // Display loading message while fetching data
  }

  return (
    <section className="post-detail">
      <div className="container post-detail__container">
        <h1>{post.title}</h1>
        <p><strong>Category:</strong> {post.category_name}</p>
        <p><strong>Author:</strong> {post.author_name}</p>
        <div className="post-content">
          {post.content}
        </div>
        {post.image && <img src={post.image} alt={post.title} />}
      </div>
    </section>
  );
};

export default PostDetail;
