import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../images/nranzo.png'

import { RiImageEditLine } from "react-icons/ri"
import { PiCheckBold } from "react-icons/pi"

const UserProfile = () => {
  const [avatar, setAvatar] = useState(Avatar)
      {/* PROVVISORIO: DI SEGUITO NOME DI DEFAULT DEVE ESSERE STRINGA VUOTA */}
  const [name, setName] = useState('debugNNN')
  const [email, setEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  return (
      <section className="profile">
        <div className="container profile__container">
          <Link to={`/myposts/${name}`} className='btn'>I miei post</Link>

          <div className="profile__details">
            <div className="avatar__wrapper">
              <div className='profile__avatar'>
                <img src={avatar} alt="" />
              </div>
            {/* FORM to update avatar - frontend da cambiare */}
            <form className="avatar__form">
              {/* elemento.target.files[0] per accedere alla prima immagine nell'array */}
              <input type="file" name="avatar" id="avatar" onChange={e => setAvatar(e.target.files[0])} accept="png, jpg, jpeg" />
              <label htmlFor="avatar"><RiImageEditLine /></label>
            </form>
            <button className="profile__avatar-btn"><PiCheckBold /></button>
          </div>
          <h1>Shmeghi</h1>

          {/* FORM to update user details - frontend da cambiare */}
          <form className="form profile__form">
            <p className="form__error-message">Errore F: qualcosa è andato storto</p>
            <input type="text" placeholder='Nome e cognome' value={name} onChange={e => setName(e.target.value)} />
            <input type="email" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" placeholder='Password corrente' value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
            <input type="password" placeholder='Nuova password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
            <input type="password" placeholder='Conferma nuova password' value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
            <button type="submit" className="btn primary">Conferma</button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default UserProfile
