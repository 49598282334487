import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Authors = () => {
  const [authors, setAuthors] = useState([]); // Stato iniziale vuoto

  const fetchAuthors = async () => {
    try {
      const response = await fetch('/fetch_accounts.php'); // Richiama il file PHP
      const data = await response.json(); // Converte la risposta in JSON
      setAuthors(data); // Aggiorna lo stato con i dati dal database
    } catch (error) {
      console.error('Errore durante il fetch:', error);
    }
  };
  
  useEffect(() => {
    fetchAuthors(); // Chiama la funzione fetch all'inizio
  }, []);
  
  return (
    <section className="authors">
      {authors.length > 0 ? <div className="container authors__container">
        {
          authors.map(({ id, avatar, name, num_posts }) => {
            return <Link key={id} to={`/posts/users/${id}`} className='author'>
              <div className="author__avatar">
                <img src={avatar} alt={`Image of ${name}`} />
              </div>
              <div className="author__info">
                <h4>{name}</h4>
                <p>{num_posts} post</p>
              </div>
            </Link>
          })
        }
      </div> : <h2 className='center'>Nessun utente/autore trovato.</h2>}
    </section>
  );
};

export default Authors
