import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Logo from "../images/logo_trasp.png"
import { HiOutlineBars3 } from "react-icons/hi2"
import { IoClose } from "react-icons/io5"

const Header = () => {
    /* false su small decices */
    const [isNavShowing, setIsNavShowing] = useState(window.innerWidth > 800 ? true : false)

    const closeNavHandler = () => {
        if(window.innerWidth < 800) {
            setIsNavShowing(false);
        } else {
            setIsNavShowing(true);
        }
    }
    
    /* PROVVISORIO: DI SEGUITO NOME A CASO */
    const path = [{name: "debugNranzo"}]

  return (
    <nav>
        <div className="container nav__container">
            <Link to="/" className='nav__logo' onClick={closeNavHandler}>
                <img src={Logo} alt="Navbar Logo"/>
            </Link>
            {isNavShowing && <ul className="nav__menu">
                <li><Link to={`/profile/${path[0].name}`} onClick={closeNavHandler}>nRanzo</Link></li>
                <li><Link to="/create" onClick={closeNavHandler}>Create Post</Link></li>
                <li><Link to="/authors" onClick={closeNavHandler}>Authors</Link></li>
                <li><Link to="/logout" onClick={closeNavHandler}>Logout</Link></li>
            </ul>}
            <button className="nav__toggle-btn" onClick={() => setIsNavShowing(!isNavShowing)}>
                {isNavShowing ? <IoClose/> : <HiOutlineBars3/>}
            </button>
        </div>
    </nav>
  )
}

export default Header
