import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import PostDetail from './pages/PostDetail';
import Register from './pages/Register';
import Login from './pages/Login';
import UserProfile from './pages/UserProfile';
import Authors from './pages/Authors';
import CreatePost from './pages/CreatePost';
import EditPost from './pages/EditPost';
import DeletePost from './pages/DeletePost';
import Logout from './pages/Logout';
import CategoryPosts from './pages/CategoryPosts';
import Dashboard from './pages/Dashboard';
import AuthorPosts from './pages/AuthorPosts';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "posts/:id", element: <PostDetail /> },
      { path: "register", element: <Register /> },
      { path: "login", element: <Login /> },
      { path: "profile", element: <UserProfile /> },
      { path: "authors", element: <Authors /> },
      { path: "create-post", element: <CreatePost /> },
      { path: "edit-post/:id", element: <EditPost /> },
      { path: "delete-post/:id", element: <DeletePost /> },
      { path: "logout", element: <Logout /> },
      { path: "categories/:category", element: <CategoryPosts /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "author-posts/:authorId", element: <AuthorPosts /> },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;