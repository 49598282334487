import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostItem from '../components/PostItem';

const CategoryPosts = () => {
  const { category } = useParams(); // Get the category from the URL
  const [posts, setPosts] = useState([]); // Initial empty state

  // Fetch posts by category
  const fetchCategoryPosts = async () => {
    try {
      const response = await fetch(`/fetch_posts.php?type=by_category&category=${category}`); // Call the PHP file with the category
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json(); // Convert the response to JSON
      setPosts(data); // Update the state with data from the database
    } catch (error) {
      console.error('Error fetching category posts:', error);
    }
  };

  useEffect(() => {
    fetchCategoryPosts(); // Call the fetch function on mount
  }, [category]);

  return (
    <section>
      {posts.length > 0 ? (
        <div className="container posts__container">
          {posts.map(({ id, thumbnail, category_name, title, content, author_name }) => (
            <PostItem
              key={id}  // key = postID = id allows no error due to duplicate on server
              postID={id}
              thumbnail={thumbnail}
              category={category_name}
              title={title}
              content={content}
              author={author_name}
            />
          ))}
        </div>
      ) : (
        <h2 className='center'>Nessun Post Trovato</h2>
      )}
    </section>
  );
};

export default CategoryPosts;
