import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const CreatePost = () => {
  const [title, setTitle] = useState('')
  {/* ENUM per category? */}
  const [category, setCategory] = useState('Nessuna categoria')
  {/* meglio BODY come nome? */}
  const [description, setDescription] = useState('')
  const [thumbnail, setThumbnail] = useState('')

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'ident': '-1'}, {'ident': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold','italic','underline','strike','blockquote',
    'list','bullet','indent',
    'link','image'
  ]

  // dovrebbe essere in data, così è ridondante
  const POST_CATEGORIES = ["Bmw", "Lexus", "Desktop", "Mazda", "Nissan", "Porsche", "Subaru"]

  return (
    <section className="create-post">
      <div className="container">
        <h2>Crea un nuovo post</h2>
        <p className="form__error-message">
          ErroreF in CreatePost
        </p>
        <form className="form create-post__form">
          <input type="text" placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <select name="category" value={category} onChange={e => setCategory(e.target.value)}>
            {
              POST_CATEGORIES.map(categ => <option key={categ}>{categ}</option>)
            }
          </select>
          <ReactQuill theme="snow" modules={modules} formats={formats} value={description} onChange={setDescription}/>
          <input type="file" onChange={e => setThumbnail(e.target.files[0])} accept='png, jpg, jpeg' />
          <button type="submit" className='btn primary'>Crea post</button>
        </form>
      </div>
    </section>
  )
}

export default CreatePost
