import React, { useState, useEffect } from 'react';
import PostItem from './PostItem';

const Posts = () => {
  const [posts, setPosts] = useState([]); // Initial empty state

  // Fetch all posts
  const fetchAllPosts = async () => {
    try {
      const response = await fetch('/fetch_posts.php?type=all'); // Call the PHP file
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json(); // Convert the response to JSON
      setPosts(data); // Update the state with data from the database
    } catch (error) {
      console.error('Error fetching all posts:', error);
    }
  };

  useEffect(() => {
    fetchAllPosts(); // Call the fetch function on mount
  }, []);

  return (
    <section className="posts">
      {posts.length > 0 ? (
        <div className="container posts__container">
          {posts.map(({ id, title, category_name, content, author_name, image, thumbnail, timestamp, avatar }) => (
            <PostItem
              key={id}  // key = postID = id allows no error due to duplicate on server
              postID={id}
              title={title}
              category={category_name}
              content={content}
              author={author_name}
              image={image}
              thumbnail={thumbnail}
              timestamp={timestamp}
              avatar={avatar}
            />
          ))}
        </div>
      ) : (
        <p>No posts available</p>
      )}
    </section>
  );
};

export default Posts;
